.footer {
  /* width: 100%;
  height: 80px;
  background-color: #4d4762;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 10px; */
  /* margin-top: 1rem; */
  padding: 50px 0;
  background: rgb(114, 113, 110) /* optional */;
 
  text-align: center; /* optional */
  position: absolute;

  width: 100%;
  left: 0;
  height: 10vh;
  margin-bottom: -450px

 
}

.socialMedia svg {
  color: white;
  margin-top: 10%;
  font-size: 30px;
  cursor: pointer;
  vertical-align:baseline;
}

.footer p {
  color: white;
}
/* .fa-instagram {
  color: transparent;
  background: -webkit-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
} */

@media ( max-width: 15.25em ) {
  .spanText {
      font-size: 0.275em;
      align-content: center;
      align-items: center;
      padding: '5px';
      margin-top: 12px;

    }
   }
   .spanText { 
    font-size: 1.925rem;
    margin-top: 12px;
    padding: '5px';
    align-content: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #f7f7f7;

}