.about {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column; 
}

.aboutTop {
  width: 100%;
  height: 35%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  margin-top: 15px;
}

.aboutBottom p {
  width: 90%;
  font-size: 15px;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-top:10px
}

.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 50px;
  color: rgb(154, 106, 106);
  height: 20px;
  /* display: flex; */
  justify-content: center;
  transform: translateY(-40px);
  margin-bottom: 1px;
  margin-top: 16px;
}
