/* .main {
    height: 50vh;
    width: 50vw;
  }
  
  .map-container {
    height: 100%;
    width: 100%;
  } */

  .intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #6e6973;
    color: #ffffff;
    height: 25vh;
    padding: 20px;
    /* margin-bottom: 125px; */

  }
  
  .intro-h2 {
    margin:0;
  }
  
  .intro-p {
    max-width: 100vw;
  }
  
  @media screen and (min-width: 699px) {
    .intro-container {
      justify-content: start;
      align-items: flex-start;
      text-align: left;
      padding: 5%;
      height: 28vh;
  
    }
  
    .intro-p {
      max-width: 750px;
    }
  }
  
  /* contact info css */
  .info {
    text-align: left;
    padding: 10px;
    background-color: #6e6973;
    color: #ffffff;
  

  }
  
  .info-h2 {
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 1%;
  }
  
  .info-details-container {
    margin-top: 1%;
  }
  
  .info-detail {
    font-weight: 600;
  }
  
  .info-icons-container {
    display: flex;
    width: 100%;
    margin-bottom: 20%;
  }
  
  .info-icon {
    font-size: 2.5rem;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 5px;
    justify-content: center;

  }
  
  @media screen and (min-width: 699px) {
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* padding: 40px; */
    }
  
    .info-detail {
      line-height: 2;
    }
  }
  
  /* form css */

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 20px;
    background-color: aliceblue;
  
  }
  
  .form-h2 {
    margin: 0;
    text-transform: uppercase;
  }
  
  .form-label {
    margin: 10px 0;
    text-align: left;
    width: 100%;
  }
  
  .form-input,
  .form-textarea {
    display: block;
    margin-top: 5px;
    font-size: 1rem;
    width: calc(100% - 20px);
    border: 1px solid var(--main-blue);
    border-radius: 2px;
    padding: 10px;
  }
  
  .form-textarea {
    height: 250px;
  }
  
  .form-textarea::placeholder {
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 400;
  }
  
  .form-submit {
    justify-self: end;
    font-size: 5rem;
    color: var(--main-green);
  }
  
  @media screen and (min-width: 799px) {
    .form {
      display: grid;
      grid-template-areas:
        'header .'
        'name message'
        'tel message'
        'email submit';
      align-items: start;
      align-content: space-between;
      justify-content: center;
      gap: 0.5vw 1vw;
      padding: 40px;
    }
  
    .form-h2 {
      grid-area: header;
      text-align: left;
    }
  
    .form-label {
      margin: 0;
    }
  
    #name {
      grid-area: name;
    }
    #email {
      grid-area: email;
    }
    #tel {
      grid-area: tel;
    }
  
    #message {
      grid-area: message;
      height: 100%;
    }
  
    .form-textarea {
      height: 150px;
    }
  
    .form-submit {
      grid-area: submit;
    }
  }

  /* combine section css */
  .contact-section {
    background-color:white;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  
  @media screen and (min-width: 799px) {
    .contact-section {
      /* --offset-height: -100px; */
  
      display: grid;
      grid-template-columns: 3fr 1.5fr;
      width: 80vw;
      height: 410px;
      /* margin: 0 auto; */
      /* top: var(--offset-height); */
      left: 50%;
      margin-left: -40vw;
      /* margin-bottom: var(--offset-height); */
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);

    }
  }
  


  /* just trying out map with iframe */
  .googlemap {
    background-color: blue;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    padding-bottom: 0;
  }
  
  .google-iframe {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border: 2px solid red;
  }

  